// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("jquery");
require("bootstrap");
require("bootstrap-datepicker");
require("select2");
require("@nathanvda/cocoon")

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import $ from 'jquery';
global.$ = jQuery;
var moment = require("moment")

window.moment = moment

import "../stylesheets/application";

import "@fortawesome/fontawesome-free/js/all";
import "./custom";
import "./shared/pusher";
import "./session";
import "./modal"
import "../source";
import "../../../vendor/assets/custom/daterangepicker.min";
import "chartkick/chart.js";

require("trix")
require("@rails/actiontext")
