$(document).on('turbolinks:load', function() {
  $(document).find("select").not(".select2-hidden-accessible").select2()
  $('.login').on('click',function(){
    $('#g-recaptcha-response')[0].value = $('.g-recaptcha-response')[0].value
  })

  $('a.modal-ajax').on('click', function(event) {
    event.preventDefault()
    const url = $(this).data('url')
    var header = $(this).data('header')
    if (typeof(header) == 'undefined' || header == '') {
      header = $(this).text()
      if (header == '') {
        header = 'Edit'
      }
    }

    $('#modal').find(".modal-content .modal-ajax-body").load(url, function() {
      $('#modal').find('.modal-title').text(header)
      $('#modal').modal('show')
      $('.select2').select2()
    })
  })

  $('a.modal-destroy-account').on('click', function(event) {
    $('#delete-account-modal').modal('show')
    $('.btn-delete-account').attr('data-url', $(this).data("url"))
    $('.btn-delete-account').attr('data-account-name', $(this).data("account-name"))
    $(".delete-confirmation-info").text("Type " + $(this).data("account-name")  + " to confirm");
  })

  $('.js-multi-menu--clickonly .multi-menu-parent').on('click', function(event) {
    event.stopPropagation()
    if ($(this).parent('ul').parent('.multi-menu').length && $(this).hasClass('active')) {
      $(this).parent('ul').find('li').removeClass('active')
    } else {
      $(this).parent('ul').find('li').removeClass('active')
      $(this).toggleClass('active')
    }
  })

  $(document).on('change', 'select.select-hardware-endpoint-role', function(event) {
    $('#priority').toggle($(this).val() == 'smartbox')
    $('.os-type').toggle($(this).val() == 'kiosk')
  })

  $(document).on('change', 'select.select-kiosk-mode', function(event) {
    const kioskMode = $(this).val()
    $('#kiosk_locale, #show_locker_number').toggle(kioskMode == 'amazon_kiosk')
    $('#sector, #little_endian').toggle(kioskMode == 'ecs_activator')
    if(kioskMode == 'ecs_activator'){
      $('#kiosk-sector, #little-endian').removeClass("d-none")
    } else {
      $('#kiosk-sector, #little-endian').addClass("d-none")
    }
    $('#sector').toggle(kioskMode == 'card_activator')
    $('#little_endian').toggle(kioskMode == 'user_activator')
    if(kioskMode == 'user_activator'){
      $('#little-endian').removeClass("d-none")
    } else {
      $('#little-endian').addClass("d-none")
    }
    $('#passive_scan').toggle(kioskMode == 'collect_kiosk')
    if(kioskMode == 'ecs_activator' || kioskMode == 'amazon_kiosk' ||
      kioskMode == 'collect_kiosk' || kioskMode == 'user_activator') {
      $('#kiosk_submode').show()
      $('#kiosk-submode').removeClass("d-none")
      let submodeSelect = $('.select-kiosk-submode')
      submodeSelect.html(null)
      if(kioskMode == 'ecs_activator') {
        submodeSelect.append($('<option value="pacs">PACS</option>')).append($('<option value="csn">CSN</option>'))
      }
      if(kioskMode == 'amazon_kiosk') {
        submodeSelect.append($('<option value="location_selector">Location Selector</option>')).append($('<option value="auto_select">Auto Select</option>'))
        if($('.select-kiosk-card-type').val() == 'hid_csn') {
          submodeSelect.append($('<option value="no_allocation">No Allocation</option>'))
        }
      }
      if(kioskMode == 'collect_kiosk') {
        submodeSelect.append($('<option value="without_asset_scan">Without Asset Scan</option>')).append($('<option value="without_asset_scan_no_admin">Without Asset Scan No Admin</option>')).append($('<option value="asset_scan">Asset Scan</option>'))
      }
      if(kioskMode == 'user_activator') {
        submodeSelect.append($('<option value="on_screen_keyboard">On Screen Keyboard</option>')).append($('<option value="barcode_scan">Barcode Scan</option>'))
      }
    } else {
      $('#kiosk_submode').hide()
      $('#kiosk-submode').addClass("d-none")
    }
  })

  $(document).on('change', 'select.select-kiosk-card-type', function(event) {
    const cardType = $(this).val()
    const kioskMode = $('select.select-kiosk-mode').val()
    if(kioskMode == 'amazon_kiosk') {
      let submodeSelect = $('.select-kiosk-submode')
      submodeSelect.html(null)
      submodeSelect.append($('<option value="location_selector">Location Selector</option>')).append($('<option value="auto_select">Auto Select</option>'))
      if(cardType == 'hid_csn') {
        submodeSelect.append($('<option value="no_allocation">No Allocation</option>'))
      }
    }
  })

  $(document).on('change', 'select.select-lock-card-type', function(event) {
    if($(".warnings").parents('form').hasClass('new-record') == false) {
      const warning = $(".warnings").data('card-type-changed')
      let badge = $('<div class="badge badge-danger d-block mb-2" />')
      badge.html(warning)
      $(".warnings").html(badge)
    }
    switch($(this).val()) {
      case 'no_nfc':
        $('div.mifare-type').addClass('d-none')
        $('div.ecs-sector').addClass('d-none')
        break;
      case 'hid':
        $('div.mifare-type').addClass('d-none')
        $('div.ecs-sector').removeClass('d-none')
        break;
      default:
        $('div.mifare-type').removeClass('d-none')
        $('div.ecs-sector').removeClass('d-none')
    }
  })

  $(".custom-control-input.account").on("change", function () {
    let id = $(this).data("id")
    let name = $(this).data("name")
    let checked = $(this).is(":checked")
    let sftp = $(this)[0].id.includes("Sftp")
    let message = ""

    if (checked && !(sftp)) {
      message = "Are you sure to mark account " + name + " live ?"
    } else if (!sftp) {
      message = "Are you sure to mark account " + name + " not live ?"
    }

    if (checked && sftp) {
      message = "Are you sure to mark sftp account enable ?"
    } else if (sftp) {
      message = "Are you sure to mark sftp account disable  ?"
    }
    let confimed = confirm(message)

    if (!confimed) {
      return checked
        ? $(this).prop("checked", false)
        : $(this).prop("checked", true)
    } else {
      $("#" + id)[0].click()
    }
  })

  $(".custom-control-input.role").on("change", function () {
    let id = $(this).data("id")
    let name = $(this).data("name")
    let checked = $(this).is(":checked")
    let role = $(this)[0].id.includes("Role")
    let message = ""

    if (checked && role) {
      message = "Are you sure to mark role " + name + " enable ?"
    } else if (role){
      message = "Are you sure to mark role " + name + " disable ?"
    }

    let confimed = confirm(message)

    if (!confimed) {
      return checked
        ? $(this).prop("checked", false)
        : $(this).prop("checked", true)
    } else {
      $("#" + id)[0].click()
    }
  })

  $('body').on('click', '#check-all', function() {
    $('table.selectable-rows tr td input:checkbox').prop('checked', $(this).is(':checked'))
  })

  $('table.selectable-rows tr td').on('click', 'input:checkbox', function() {
    if($(this).not(':checked')){
      $('#check-all').prop('checked', false)
    }
  })

  $('.delete-selected').on("click",function(event) {
    event.preventDefault()

    var selectedRows = $('table.selectable-rows tbody input:checkbox:checked')
    if (selectedRows.length > 0) {
      if(confirm('Are you sure want to delete selected rows?')) {
        $.post($(this).attr('href'), selectedRows.serialize(), function() {
          $.each(selectedRows, function(index, elm) { $("table.selectable-rows tr#row_" + $(elm).val()).remove(); });
        })
      }
    }
    else {
      alert('Please select some rows')
    }
  })

  $(document).on('click', 'a.user-selected', function(event) {
    event.preventDefault()

    var selectedRows = $('table.selectable-rows tbody input:checkbox:checked')
    if (selectedRows.length > 0) {
      $(".spinner").removeClass("d-none");
      $(".spinner").addClass("d-block")
      $.post(this.dataset.href+ "&course_ids=" + $("#course_id").val(), selectedRows.serialize(), function() {
        $.each(selectedRows, function(index, elm) { $("table.selectable-rows tr#row_" + $(elm).val()).remove(); });
      })
    }
    else {
      $(".spinner").addClass("d-none");
      $(".spinner").removeClass("d-block");
      alert('Please select some rows');
      $('#bulk-enroll-modal').modal('hide')
    }
  })

  $(document).on("click", "a.bulk-locker-action", function (event) {
    event.preventDefault()

    var selectedRows = $("table.selectable-rows tbody input:checkbox:checked")
    if(selectedRows.length > 0) {
      if (this.dataset.href.includes("/bulk_switch_modes/new")) {
        ids_values = selectedRows.map((idx, el) => el.value).toArray()
        params = this.dataset.href.split("?")
        orignal_url = params.shift()

        ids_values.forEach((id) => {
          params.push(`locker_ids[]=${id}`)
        })

        const url = `${orignal_url}?${params.join("&")}`

        $.get(
          url, function() {
            $("#modal").find(".modal-content .modal-ajax-body").load(url, function () {
              $("#modal").find(".modal-title").text("Bulk Switch Mode")
              $("#modal").modal("show")
            })
          }
        )
      } else if (this.dataset.href.includes("clear_allocation")){
        ids_values = selectedRows.map((idx, el) => el.value).toArray()
        params = this.dataset.href.split("?")
        orignal_url = params.shift()

        ids_values.forEach((id) => {
          params.push(`locker_ids[]=${id}`)
        })

        const url = `${orignal_url}?${params.join("&")}`

        $.get(
          url, function() {
            $("#modal").find(".modal-content .modal-ajax-body").load(url, function () {
              $("#modal").find(".modal-title").text("Clear Allocation")
              $("#modal").modal("show")
            })
          }
        )
      } else if (this.dataset.href.includes("bulk_update_status")) {
        $("#bulk-update-status").modal("show")
        ids_values = ''
        $.each(selectedRows, function (index, elm) {
          ids_values += "," + elm.value
        })
        $("#status_modal_lockers_ids").val(ids_values)
      } else if (this.dataset.href.includes("bulk_update_location")) {
        $("#bulk-update-location").modal("show")
        ids_values = ""
        $.each(selectedRows, function (index, elm) {
          ids_values += "," + elm.value
        })
        $("#location_modal_lockers_ids").val(ids_values)
      } else {
        $.post(this.dataset.href, selectedRows.serialize(), function () {
          $.each(selectedRows, function (index, elm) {
            elm.checked = false
          })
        })
      }
    }
    else {
      alert("Please select some rows")
    }
  })

  $(document).on('change', '#locker_system_dropdown', function()
    {
      var system_type = this.options[this.options.selectedIndex].dataset.systemType
      system_type = system_type.replace(system_type[0], system_type[0].toLowerCase())
      var mode_options = JSON.parse($(".options")[0].dataset[system_type])
      $("#locker_mode_dropdown").empty()
      for(var i=0; i< mode_options.length; i++){
        $("#locker_mode_dropdown").append(
          '<option value="' + mode_options[i][1] + '">' + mode_options[i][0] + '</option>');
      }
    }
  )

  $(document).on('click', 'a.bulk-he-action', function(event) {
    event.preventDefault()

    var selectedRows = $('table.selectable-rows tbody input:checkbox:checked')
    if (selectedRows.length > 0) {
      $.post(this.dataset.href, selectedRows.serialize(), function() {
        $.each(selectedRows, function(index, elm) { $("table.selectable-rows tr#row_" + $(elm).val()).remove(); });
      })
    }
    else {
      alert('Please select some rows');
    }
  })

  $('body').on('change', '.cb-all-locations', function(event) {
    $('.select-locations').toggle(!this.checked)
  })

  $(document).ready(function() {
    $.each($(".select2"), function (index, selectElm) {
      if (selectElm.tagName === "SELECT") {
        $(selectElm).select2();
      }
    });
  });

  $("#account-dropdown").change(function(){
    account_id = $(this).val()
    $.ajax({
      type: "post",
      url: "/move_lockers/find_systems",
      data: { account_id: account_id },
      success: function(data) {
        $("#system_id").html("")
        $("#location-dropdown").html("")
        data.systems.forEach(function(system){
          $("#system_id").append("<option value='"+system.id+"'>"+system.name+"</option>")
        });
        data.locations.forEach(function(location){
          $("#location-dropdown").append("<option value='"+location.id+"'>"+location.name+"</option>")
        });
      },
    })
  })

  $(document).on("click", ".btn-delete-account", function(event) {
    account_name = $(this).data("account-name")

    if ($(".delete-field").val() == account_name) {
      $(".delete-danger-text").hide();
      $.ajax({
        type: "DELETE",
        url: $(this).data("url")
      })
    }else{
      $(".delete-danger-text").show();
    }
  })

  $(".move-modal-btn").on("click", function(){
    var locker_ids = []
    var selectedRows = $("table.selectable-rows tbody input:checkbox:checked")
    selectedRows.each(function(){
      locker_ids.push($(this).val())
    });
    if (selectedRows.length > 0) {
      $("#invalid-lockers").text("");
      $.ajax({
        type: "post",
        url: "/move_lockers/validate_lockers",
        data: { locker_ids: locker_ids },
        success: function(data){
          if(data.status) {
            $("#invalid-lockers").hide();
            $(".move-lockers-btn").show();
            $("#move-to-another-account").show();
            $("#locker_ids").val(data.ids);
          }else {
            $("#move-to-another-account").hide();
            $(".move-lockers-btn").hide();
            $("#invalid-lockers").show();
            $("#invalid-lockers").text(data.message);
          }
        }
      })
    }
    else {
      $("#move-to-another-account").hide();
      $(".move-lockers-btn").hide();
      $("#invalid-lockers").show();
      $("#invalid-lockers").text("Please select atleast one locker");
    }
  })
})

$(document).ready(getOptionsData)
$(document).on('page:load', getOptionsData)

function getOptionsData() {
  $(document).on('change', 'select.select-customer', function(event) {
    var $this_siblings = $(this).parent().siblings();

    $this_siblings.find('select.select-account, select.select-kiosk-account, select.select-locations').html(null)
    toggleDisableInput(
      $this_siblings.find('select.select-account, select.select-kiosk-account, select.select-location, select.select-locations'),
      true
    );

    $.get($(this).data('url') + '&end_customer_id=' + $(this).val(), function(data) {
      if (data.accounts.length > 0) {
        $this_siblings
          .find("select.select-account, select.select-kiosk-account")
          .append($("<option/>").val("").text("Select Account"))
        $this_siblings
          .find("select.select-locations, select.select-location")
          .append($("<option/>").val("").text("Select Location"))
      } else {
        $this_siblings
          .find("select.select-account, select.select-kiosk-account")
          .append($("<option/>").val("").text("No Account"))
        $this_siblings
          .find("select.select-locations, select.select-location")
          .append($("<option/>").val("").text("No Location"))
      };

      $.each(data.accounts, function(i, account) {
        $this_siblings.find('select.select-account, select.select-kiosk-account')
          .append($('<option/>').val(account.id).text(account.name))
      })
    })

    toggleDisableInput(
      $this_siblings.find('select.select-account, select.select-kiosk-account'),
      false
    );

    $.get("/end_customers/"+$(this).val()+"/kiosk_presets.json", function(data) {
      $("#apply-preset").addClass("disabled")
      $('select.select-kiosk-presets').html(null)
      $('select.select-kiosk-presets').append($('<option/>').val("").text("No Kiosk Preset Selected"))

      if(data.kiosk_presets.length > 0){
        $.each(data.kiosk_presets, function(i, preset) {
          $('select.select-kiosk-presets').append($('<option/>').val(preset.id).text(preset.name).attr(preset.data_attrs))
        })
      }
    })
  })

  $(document).on('change', 'select.select-kiosk-account', function(event) {
    var $this_siblings = $(this).parent().siblings();

    $.get($(this).data('url') + '&account_id=' + $(this).val(), function(data) {
      $this_siblings.find('select.select-locations').html(null);
      if (data.locations.length > 0) {
        $this_siblings.find('select.select-locations')
          .append($('<option/>').val("select-location").text("Select Location"));
        $.each(data.locations, function(i, location) {
          $this_siblings.find('select.select-locations').append($('<option/>').val(location.id).text(location.name))
        });
      }
    })

    toggleDisableInput($(this), false);
    toggleDisableInput($this_siblings.find('select.select-locations, select.select-location'), false);
  })

  $(document).on('change', 'select.select-account', function(event) {
    var $this_siblings = $(this).parent().siblings();

    if (
      $(this).data("url") != undefined &&
      $(this).val() !== "select-account"
    ) {
      $this_siblings.find("select.select-location").html(null)
      $this_siblings.find("select.select-location").prop("disabled", true)

      $.get(
        $(this).data("url") + "&account_id=" + $(this).val(),
        function (data) {
          if (data.locations != undefined) {
            if (data.locations.length > 0) {
              $("select.select-location").append(
                $("<option/>").val("").text("Select Location")
              )
            }
            $.each(data.locations, function (i, location) {
              $this_siblings
                .find("select.select-location")
                .append($("<option/>").val(location.id).text(location.name))
            })
            $this_siblings.find("select.select-location").prop("disabled", false)
          }
          }
      )
    }

    toggleDisableInput($(this), false);
    toggleDisableInput($this_siblings.find('select.select-locations, select.select-location'), false);
  })

  function toggleDisableInput(elm, disableState) {
    if (elm.length != 0 && elm.prop("disabled") != disableState) {
      elm.prop("disabled", disableState)
    }
  }

  $(document).on("click", "a.assign-kiosk", function(event) {
    event.preventDefault();

    $("#kiosk_end_customer_id").removeClass("d-none");
    $("#kiosk_account_id").parent().removeClass("d-none");
    $("#kiosk_location_ids").parent().removeClass("d-none");
    $("#unassigned").val('false')
    $(this).addClass("d-none");
  })

  $(document).on("click", "a.assign-smartbox", function(event) {
    event.preventDefault();

    $("#hardware_endpoint_end_customer_id").removeClass("d-none");
    $("#hardware_endpoint_account_id").parent().removeClass("d-none");
    $("#hardware_endpoint_location_id").parent().removeClass("d-none");
    $("#unassigned").val('false')
    $(this).addClass("d-none");
  })

  $(document).on('change', 'select.select-kiosk-presets', function(event) {
    if ($(this).val() != ""){
      $("#apply-preset").removeClass("disabled")
    } else {
      $("#apply-preset").addClass("disabled")
    }
  })

  $(document).on("click", "a#apply-preset", function(event) {
    event.preventDefault();
    selected_preset = $("#preset").find('option:selected');

    $("select.select-kiosk-mode").val(selected_preset.data("kiosk_mode")).change()
    $("select.select-kiosk-card-type").val(selected_preset.data("kiosk_card_type")).change()
    $("select.select-kiosk-submode").val(selected_preset.data("kiosk_submode")).change()
    $("#kiosk_little_endian").attr("checked", selected_preset.data("little_endian"))
    $("#kiosk_sector").val(selected_preset.data("sector"))
  })
}

$(document).on("click", ".password-eye", function () {
  if (window.location.href.includes("kiosks")) {
    var input = $("#kiosk_password")
  } else {
    var input = $("#hardware_endpoint_password")
  }
  if (input.attr("type") === "password") {
    input.attr("type", "text")
  } else {
    input.attr("type", "password")
  }
})

$(document).on('turbolinks:load', function() {
  $('body').on('change', '.select-trail', function(event) {
    if($(this).val() == 'true'){
      $('.expiry-fields').removeClass("d-none")
    }
    else{
      $('.expiry-fields').addClass("d-none")
    }
  })
})

document.addEventListener('turbolinks:before-cache', function() {
  $('.select2-hidden-accessible').select2('destroy');
});

$(document).on("click", ".nav-tabs a.nav-link", function (e) {
  var url = $(this).attr("data-url");
  var loaded = $(this).data("loaded");
  var auditTrailTab = $($(this).attr('href'));

  if (url && !loaded && (auditTrailTab.length != 0)) {
    e.preventDefault();
    $(this).data("loaded", true);
    auditTrailTab.load(url, function(_result) {
      $(this).tab("show");
    });
  }
});

$(document).on("click", "a.load-more-audits", function (e) {
  var url = $(this).attr("data-url");
  var auditContainer = $("#audits-container");

  if (url && (auditContainer.length != 0)) {
    e.preventDefault();
    fetch(url).then(function(response){
      return response.text()
    }).then(function(html) {
      auditContainer.append(html)

      // move loading rows to very bottom and hide it
      $loadingRows = $(auditContainer.find(".loading-row")[0])
      $loadingRows.remove()
    }).catch(function(err) {
      alert("hiccup! something went wrong")
    })
  }
});

$(document).on("turbolinks:load", function() {
  toggleSelectAll();
});

function selectAll(elm, policy_name) {
  $(elm).data("checked-all", countAllRoleCheckboxes(policy_name));
  $(`#check_all_${policy_name}`).text("Unselect All");
}

function unselectAll(elm, policy_name) {
  $(elm).data("checked-all", countAllRoleCheckboxes(policy_name));
  $(`#check_all_${policy_name}`).text("Select All");
}

function countAllRoleCheckboxes(policy_name) {
  return $(`.role-checkbox[data-policy-name='${policy_name}']`).length
}

function countCheckedRoleCheckboxes(policy_name) {
  return $(`.role-checkbox[data-policy-name='${policy_name}']:checked`).length
}

function toggleSelectRoleFlag(elm, policy_name) {
  if (countAllRoleCheckboxes(policy_name) == countCheckedRoleCheckboxes(policy_name)) {
    selectAll(elm, policy_name);
  } else {
    unselectAll(elm, policy_name);
  }
}

function toggleSelectAll() {
  // initialize check-all checkboxes
  $.each($(".check-all"), function(i, check_all_link) {
    var elm = $(this);
    var policy_name = $(this).data("check-target");

    toggleSelectRoleFlag(elm, policy_name);
  });

  // initialize check-all checkboxes click trigger
  $(".check-all").click(function() {
    var policy_name = $(this).data("check-target");

    if (countAllRoleCheckboxes(policy_name) == countCheckedRoleCheckboxes(policy_name)) {
      $(`.checkbox_${policy_name}`).prop("checked", false);
      unselectAll($(this), policy_name);
    } else {
      $(`.checkbox_${policy_name}`).prop("checked", true);
      selectAll($(this), policy_name);
    }
  });

  // initialize every single checkboxes click trigger
  $.each($(".role-checkbox"), function(i, checkbox) {
    var policy_name = $(checkbox).data("policy-name");

    $(checkbox).click(function() {
      var elm = $(`#check_all_${policy_name}`);

      toggleSelectRoleFlag(elm, policy_name);
    });
  });
}

document.addEventListener("turbolinks:before-cache", function() {
  $(document).off("click", "a.btn-note, a.load-more-notes")
});

$(document).on("turbolinks:load", function() {
  $(document).on("keyup", "textarea.note-input", function() {
    var empty = false;
    $(".note-input").each(function() {
      if ($(this).val().length == 0) {
        empty = true;
      }
    });

    if (empty) {
        $("a.btn-note").addClass("disabled");
    } else {
        $("a.btn-note").removeClass("disabled");
    }
  });

   $(document).on("click", "a.load-more-notes", function(e) {
    e.preventDefault();
    var loaded = $(this).data("loaded")
    // set this tab as loaded, make sure revisiting this tab won't reload the data
    $(this).data("loaded", true)
    var url = $(this).data("url")
    var target = $(this).data("container")

    if (loaded != true) {
      // Hide old load-more link and show loading animation
      $(target).find("tr.load-more").hide()
      $(target).find(".load-more-footer").hide()
      $(target).find(".loading-row").removeClass("d-none")

      fetch(url).then(function(response){
        return response.text()
      }).then(function(html) {
        $(target).append(html)

        // move loading rows to very bottom and hide it
        $loadingRows = $(target).find(".loading-row")
        $loadingRows.addClass("d-none")
        $(target).find(".loading-row").remove()
        $(target).append($loadingRows)
      }).catch(function(err) {
        alert("hiccup! something went wrong")
      })
    }
  })

  $(document).on("click", "a.btn-note", function(e){
    e.preventDefault();
    let postNote = $(".note-input").val();
    let url = $(this).data("url");
    let token = $(this).data("token");
    let data;

    if ($(".view-mode-option input[type='checkbox']").length > 0){
      data = "&comment[body]="+encodeURIComponent(postNote)+"&authenticity_token="+encodeURIComponent(token);
      for (i = 0; i < $(".view-mode-option input[type='checkbox']").length; i++) {
        if ($(".view-mode-option input[type='checkbox']")[i].checked){
          data = data + "&comment[status]="+$(".view-mode-option input[type='checkbox']")[i].id
        }
      }
      $(".view-mode-option input[type='checkbox']").prop("checked", false);
    } else {
      data = "&note="+encodeURIComponent(postNote)+"&authenticity_token="+encodeURIComponent(token);
    }

    if (postNote == "") {
      $("a.btn-note").addClass("disabled");
    } else {
      fetch(url+data, { method: "POST" }
      ).then(function(response){
        return response.text()
      }).then(function(html) {
        $(".timeline").prepend(html)
      }).catch(function(err) {
        alert("something went wrong")
      })

      $(".note-input").val("");
      $("a.btn-note").addClass("disabled");
    }
  })

  $(document).on("click", ".view-mode-option input[type='checkbox']", function() {
    $("input[type='checkbox']").not(this).prop("checked", false);
  });
})
