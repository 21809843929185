$(document).ready(() => {
  $(document).on("change", "#hardware_endpoint_location_enabled", (e) => {
    reboot_element = $("#hardware_endpoint_reboot_loop_notification_enabled");
    operational_element = $("#hardware_endpoint_is_operational");
    priority_element = $("#hardware_endpoint_priority");
    if ($(e.target).val() == "false") {
      reboot_element.val("false");
      operational_element.val("false");
      priority_element.val("");
      reboot_element.prop("disabled", true);
      operational_element.prop("disabled", true);
      priority_element.prop("disabled", true);
    } else {
      reboot_element.prop("disabled", false);
      operational_element.prop("disabled", false);
      priority_element.prop("disabled", false);
    }
  });

  $(document).on("click", ".select-all-filtered-lockers", (e) => {
    $(".filtered-lockers-selector").toggle(e.target.checked == false)
  })

  $(document).on("change", ".select-filter-lockers", (e) => {
    let listType = $(e.target).val()
    listType = listType.charAt(0).toUpperCase() + listType.slice(1)
    $(".smartbox-filtered-lockers-tab").toggle($(e.target).val() != "disabled").find(".nav-link").text("Smartbox " + listType)
  })
})
