let obs;

$(document).on("ready turbolinks:load", function(e) {
  obs && obs.disconnect();
  initializeSelect2(true);

  $(document).on("click", "a.modal-ajax", function (event) {
    event.preventDefault()
    var url = $(this).data("url")
    var header = $(this).data("header")
    if (typeof header == "undefined" || header == "") {
      header = $(this).text()
      if (header == "") {
        header = "Edit"
      }
    }

    var modalSize = $(this).data("modal-size") || "modal-lg"
    $("#modal .modal-dialog").removeClass("modal-xl modal-lg modal-md modal-sm")
    $("#modal .modal-dialog").addClass(modalSize)

    $("#modal")
      .find(".modal-content .modal-ajax-body")
      .load(url, function () {
        $(".select2").each((idx, el) => {
          var options = {}
          if (el.dataset.maximumLength !== undefined)
            options["maximumSelectionLength"] = el.dataset.maximumLength
          $(el).select2(options)
        })
        $("#modal").find(".modal-title").text(header)
        $("#modal").modal("show")
      })
  })
})

// Initialize select2 on modal.
// add `select2` class to select field on modal for it to be triggered
function initializeSelect2() {
  const targetNode = document.getElementById("modal");
  const $targetNode = $(targetNode)
  const config = { childList: true, subtree: true };

  const callback = function(mutationsList, observer) {
    for(const mutation of mutationsList) {
      if (mutation.type === "childList") {
        const $selectElements = $targetNode.find(".select2")
        if (!$selectElements.data("select2")) {
          $selectElements.each((idx, el) => {
            var options = {}
            if (el.dataset.maximumLength !== undefined) options["maximumSelectionLength"] = el.dataset.maximumLength
            $(el).select2(options);
          })
        }
      }
    }

    obs = observer;
  };
  if (targetNode && targetNode.nodeType == Node.ELEMENT_NODE) {
    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
  }
}

function hideModal() {
  setTimeout(function(){
    $("#modal").modal("hide")
    $("#modal .modal-ajax-body").html("")
  }, 200)
}

global.hideModal = hideModal;
