$(document).on('turbolinks:load', function() {
  $('.password-eye').click(function(){
    var input = $('#user_password');
    if(input.attr('type') === 'password'){
      input.attr('type', 'text');
    }else{
      input.attr('type', 'password');
    }
  });

  $('.password-eye-confirm-password').click(function(){
    var input = $('#user_password_confirmation');
    if(input.attr('type') === 'password'){
      input.attr('type', 'text');
    }else{
      input.attr('type', 'password');
    }
  });
})
